import moment from 'moment';
import { TinyDatePicker, DateRangePicker } from 'tiny-date-picker/dist/date-range-picker';
import arrayFrom from 'array-from';

import GridDataView from '../GridDataView';
import { dispatchCustomEvent, addClass, removeClass, toggleClass } from '../../../tools/helpers';

/**
 * This function gives back the langVariables-Object used in the Datepicker.
 * It sets the Labels for the different parts of the Date- and RangePicker.
 * @param {*} lang - the lang-code (i.e. 'de' or 'en' at this point).
 */
const langOpts = (lang) => {
  let langVariables = null;
  switch (lang) {
    case 'de':
    default:
      langVariables = {
        days: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        today: 'Heute',
        clear: 'Löschen',
        close: 'Schließen',
      };
      break;
    case 'en':
      langVariables = {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Dezember'],
        today: 'Today',
        clear: 'Clear',
        close: 'Close',
      };
  }

  return langVariables;
};

const massnahmenEnWG132 = 'MASSNAHMEN_EnWG132';

class GridDataModel {
  constructor(options = {}) {
    this.element = options.element;
    this.type = options.type;
    this.display = options.display;

    this.viewOptions = options.viewOptions;
    this.itemFetch = this.viewOptions.isHistory === 'true' ? 'versionedItems' : 'items';
    this.config = options.config;
    this.index = options.index;
    this.lang = document.querySelector('html').lang || 'de';
    this.date = moment(this.config.latest, 'DD.MM.YYYY');
    this.data = {};
    this.startDate = moment(this.config.latest, 'DD.MM.YYYY');
    this.endDate = moment(this.config.latest, 'DD.MM.YYYY');
    switch (this.type) {
      case 'BILANZKREISABWEICHUNGOLD':
        this.viewOptions.showDecimalTwo = true;
        break;
      default:
        this.viewOptions.showDecimalTwo = false;
        break;
    }
  }

  init() {
    // Test Data for the History Indicator.
    // Not in use in Production but useful for local Development.
    this.historyTestData = [
      {
        latestVersion: {
          date: '25.10.2018 11:00',
          timeRange: '11:00 - 11:00',
          values: [],
          additional: [
            '25.10.2018 - 25.10.2018',
            '11:00 - 15:00',
            'Last',
            '22',
            '14.05.2020 10:49',
            '-',
          ],
        },
        versionHistory: [
          {
            date: '25.10.2018 11:00',
            timeRange: '11:00 - 11:00',
            values: [],
            additional: [
              '25.10.2018 - 25.10.2018',
              '11:00 - 15:00',
              'Last',
              '22',
              '14.05.2020 10:49',
              '-',
            ],
          },
          {
            date: '25.10.2018 11:00',
            timeRange: '11:00 - 11:00',
            values: [],
            additional: [
              '25.10.2018 - 25.10.2018',
              '11:00 - 15:00',
              'Last',
              '22',
              '09.08.2019 11:10',
              '-',
            ],
          },
        ],
      },
      {
        latestVersion: {
          date: '25.10.2018 14:00',
          timeRange: '14:00 - 14:00',
          values: [],
          additional: [
            '25.10.2018 - 25.10.2018',
            '14:00 - 17:00',
            'Bergkamen',
            '33',
            '09.08.2019 11:10',
            '-',
          ],
        },
        versionHistory: [],
      },
    ];

    this.initDatePickers();
    this.initDateChange();
    this.initTriggers();
    this.initDownloads();

    window.addEventListener(`row-${this.index}-range-changed`, () => {
      this.initDownloads();
    });

    this.fetchApi();
  }

  /**
   * Init the Datepickers and if Show-Datepicker is false hide the filters.
   */
  initDatePickers() {
    if (this.viewOptions.showDatepicker === 'true') {
      this.datepickerSingle = new TinyDatePicker(this.element.querySelector(`.${this.viewOptions.baseClass}__calendar-single__trigger input`), {
        appendTo: this.element.querySelector(`.${this.viewOptions.baseClass}__calendar-single`),
        mode: 'dp-modal',
        dayOffset: 1,
        max: moment(this.config.latest, 'DD.MM.YYYY').format('MM/DD/YYYY'),
        min: moment(this.config.first, 'DD.MM.YYYY').format('MM/DD/YYYY'),
        lang: langOpts(this.lang),
      });

      this.datepickerSingle.state.view = 'year';

      this.datepickerSingle.on({
        select: (_, dp) => {
          this.date = dp.state.selectedDate;
          dispatchCustomEvent(`row-${this.index}-date-changed`, {
            date: this.date,
          });
          this.fetchApi();
        },
        close: () => {
          this.calendarSingleOpen = false;
        },
      });

      this.datepickerRange = new DateRangePicker(this.element.querySelector(`.${this.viewOptions.baseClass}__calendar-start`), {
        appendTo: this.element.querySelector(`.${this.viewOptions.baseClass}__calendar-start`),
        mode: 'dp-permanent',
        startOpts: {
          dayOffset: 1,
          max: moment(this.config.latest, 'DD.MM.YYYY').format('MM/DD/YYYY'),
          min: moment(this.config.first, 'DD.MM.YYYY').format('MM/DD/YYYY'),
          lang: langOpts(this.lang),
        },
        endOpts: {
          dayOffset: 1,
          max: moment(this.config.latest, 'DD.MM.YYYY').format('MM/DD/YYYY'),
          min: moment(this.config.first, 'DD.MM.YYYY').format('MM/DD/YYYY'),
          lang: langOpts(this.lang),
        },
      });

      this.datepickerRange.setState({
        view: 'month',
        start: new Date(moment().startOf('month').format('MM/DD/YYYY')),
        end: new Date(moment(this.config.latest, 'DD.MM.YYYY').format('MM/DD/YYYY')),
      });

      this.datepickerRange.on({
        statechange: (_, dp) => {
          this.startDate = dp.state.start;
          this.endDate = dp.state.end;
          dispatchCustomEvent(`row-${this.index}-range-changed`, {
            startDate: this.startDate,
            endDate: this.endDate,
          });
        },
      });
    } else {
      addClass(this.element.querySelector(`.${this.viewOptions.baseClass}__filters`), 'hidden');
    }

  }

  /**
   * Init the Trigger Functionality for Opening and Hiding DatePicker and Download Area.
   */
  initTriggers() {
    this.calendarSingleTrigger = this.element.querySelector(`.${this.viewOptions.baseClass}__calendar-single__trigger`);
    this.calendarSingleTrigger.addEventListener('click', () => {
      if (!this.calendarSingleOpen) {
        this.datepickerSingle.open();
        this.calendarSingleOpen = true;
      } else {
        this.datepickerSingle.close();
      }
    });

    this.downloadsTrigger = this.element.querySelector(`.${this.viewOptions.baseClass}__downloads__trigger`);

    this.downloadsTrigger.addEventListener('click', () => {
      toggleClass(this.downloadsTrigger, 'is-active');
      const downloads = this.element.querySelector(`.${this.viewOptions.baseClass}__downloads`);
      if (!this.downloadsOpen) {
        addClass(downloads, 'active');
        this.downloadsOpen = true;
      } else {
        removeClass(downloads, 'active');
        this.downloadsOpen = false;
      }
    });
  }

  /**
   * Init the Custom Event Dispatching for Date-Change and Set new Date.
   */
  initDateChange() {
    this.dateNavButtons = this.element.querySelectorAll('[data-date-nav-type]');
    arrayFrom(this.dateNavButtons).forEach((button) => {
      button.addEventListener('click', () => {
        if (button.dataset.dateNavType === 'prev') {
          this.date = moment(this.date).subtract(1, button.dataset.unit);
          dispatchCustomEvent(`row-${this.index}-date-changed`, {
            date: this.date,
          });
        } else if (button.dataset.dateNavType === 'next') {
          this.date = moment(this.date).add(1, button.dataset.unit);
          dispatchCustomEvent(`row-${this.index}-date-changed`, {
            date: this.date,
          });
        }

        this.fetchApi();
      });
    });
  }

  /**
   * Init the Download Buttons
   */
  initDownloads() {
    const downloadButtons = this.element.querySelector(`.${this.viewOptions.baseClass}__downloads`).querySelectorAll('[data-file-type]');
    arrayFrom(downloadButtons).forEach((button) => {
      const b = button;
      b.href = `/api/grid-data/items/${button.dataset.fileType}/${this.type}/${moment(this.startDate).format('YYYY-MM-DD')}/${moment(this.endDate).format('YYYY-MM-DD')}`;
      b.type = button.dataset.fileType;
    });
  }

  /**
   * Fetch the Items from the API.
   * If it is a History Indicator a different URL is called.
   */
  fetchApi() {
    if (this.viewOptions.isHistory.toString() === 'true') {
      this.url = `/api/grid-data/${this.itemFetch}/${this.type}`;
    } else {
      // this.url = `https://internet-stage.ampintern.net/api/grid-data/items/EEG_RESTABWEICHUNG/quarterhour/${moment(this.date).format('YYYY-MM-DD')}`;
      this.url = `/api/grid-data/${this.itemFetch}/${this.type}/${this.display}/${moment(this.date).format('YYYY-MM-DD')}`;
    }

    this.headers = new Headers();

    this.fetchOptions = {
      headers: this.headers,
    };
    fetch(this.url, this.fetchOptions)
      .then(res => res.json())
      .then((res) => {
        this.data = {};

        if (this.type === massnahmenEnWG132 && (!Array.isArray(res.items) || res.items.length <= 0)) {
          this.showErrorMessage();
          this.hideDatePicker();
        } else {
          this.data.items = res;

          if (this.viewOptions.isHistory === 'false') {
            this.addValueKeys(this.data.items);
          }

          if (this.viewOptions.isHistory === 'true' && this.data.items.length === 0) {
            this.showErrorMessage();
            this.hideDatePicker();
          } else if (this.viewOptions.isHistory === 'true' && this.data.items.length > 0) {
            this.addValueKeys(this.data.items);
          }
        }
      })
      .catch((error) => {
        console.error('Failed to fetch Items: ', error);
        this.showErrorMessage();
        this.hideDatePicker();
      });

  }

  /**
   * Take the Data and reformat it accordingly.
   * @param {*} data - The Items fetched from the API.
   */
  addValueKeys(data) {
    const keys = [];

    if (data.items !== undefined && data.items.length > 0) {
      for (let i = 0; i < data.items[0].values.length; i += 1) {
        keys.push(`value${i}`);
      }
    }


    this.data.valueKeys = keys;

    this.addAdditionals(data);
  }

  /**
   * Since there are Additional Values, we have to add them to the Data-Object.
   * @param {*} data - Data received from the addValueKeys()-Function.
   */
  addAdditionals(data) {
    let additional = 0;

    if (data.length > 0 && this.viewOptions.isHistory === 'false') {
      if (data[0].additional.length > 0) {
        additional = data[0].additional.length;
      }
    }

    this.data.additional = additional;

    this.addToView();
  }

  /**
   * Finally add the Data to the view and init it.
   */
  addToView() {
    const view = new GridDataView({
      element: this.element,
      data: this.data,
      type: this.type,
      date: this.date,
      config: this.config,
      display: this.display,
      viewOptions: this.viewOptions,
      index: this.index,
    });

    view.init();
  }

  /**
   * Showing the Error Message when no Data is present.
   * We therefore take the Message from the Data-Attribute, insert in the Wrapper and Show the Wrapper.
   * there is a custom error message amd the default one
   */
  showErrorMessage() {
    this.customErrorMessageData = this.element.dataset.customErrorMessageData || null;
    this.defaultErrorMessageData = this.element.dataset.errorMessageData || null;
    const errorMessageData = this.customErrorMessageData ? this.customErrorMessageData : this.defaultErrorMessageData;
    if (errorMessageData !== null) {
      const errorMessageDataWrapper = this.element.querySelector(`.${this.viewOptions.baseClass}__error--data`);
      errorMessageDataWrapper.innerHTML = errorMessageData;
      addClass(errorMessageDataWrapper, 'shown');
    }
  }

  hideDatePicker() {
    addClass(this.element.querySelector(`.${this.viewOptions.baseClass}__filters`), 'hidden');
  }
}

export default GridDataModel;
